<template>
  <CRow>
    <CCol md="12">
      <CCard class="clapans">
        <CCardHeader>
          {{ alert_message }}<br>
          <template v-if="alert_message">
            Общая сумма: <b>{{ all_sum_wallet }}</b><br>
            Общий количество: <b>{{ all_sum_counter }}</b><br>
            Общий вес: <b>{{ all_sum_weight }}</b>
          </template>

          <template v-if="1+1==4">
            <aside></aside>
          </template>
          <div class="d-flex aligin-items-center justify-content-between">
            <div class="input-group mb-3">
              <div class="form-file">
                <input type="file" class="form-file-input" id="inputGroupFile01"
                       v-on:change="onFileChange"
                       aria-describedby="inputGroupFileAddon01">
              </div>
            </div>
            <div class="input-group ">
              <div class="input-group-prepend">
                <span class="input-group-text">Тип рейса</span>
              </div>
              <select class="form-control" v-model="file_type">
                <option>Авиа</option>
                <option>Авто</option>
              </select>
            </div>
            <div class="input-group ">
              <div class="input-group-prepend">
                <span class="input-group-text">Номер Рейса</span>
              </div>
              <input class="form-control" v-model="race_number"/>
            </div>
            <CButton color="success" @click="convertToArray()">
              Добавить
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
              class="mb-0 table-outline"
              hover
              :items="tableItems"
              :fields="tableFields"
              head-color="light"
              column-filter
              table-filter
              sorter
              :tableFilter="{'label':'Поиск','placeholder':'Введите слово для поиска'}"
          >

            <td slot="price_per_kg" slot-scope="{ item,index }">
              <CInput
                  :value.sync="item.price_per_kg"
                  custom
                  inline
                  @input="changeItem(item,index)"
                  @change="changeItem(item,index)"
              />
            </td>
            <td slot="file_city" slot-scope="{ item,index }">
              <CInput
                  :value.sync="item.file_city"
                  custom
                  inline
              />
            </td>
            <td slot="price_per_kg" slot-scope="{ item,index }">
              <CInput
                  :value.sync="item.price_per_kg"
                  custom
                  inline
                  @input="changeItem(item,index)"
                  @change="changeItem(item,index)"
              />
            </td>
            <td slot="price_per_place" slot-scope="{ item,index }">
              <CInput
                  :value.sync="item.price_per_place"
                  custom
                  inline
                  @input="changeItem(item,index)"
                  @change="changeItem(item,index)"
              />
            </td>
            <td slot="action" slot-scope="{ item,index }">
              <CButton color="success" @click="deleteItem(item,index)">
                Удалить
              </CButton>
            </td>
          </CDataTable>
          <div class="d-flex aligin-items-center justify-content-between my-4">
            <div></div>
            <CButton color="success" @click="uploadToArray()">
              Сохранить
            </CButton>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <div class="alert_block">
      <CAlert color="warning" closeButton v-if="warning">
        {{ alert_message }}
      </CAlert>
      <CAlert color="primary" closeButton v-if="success">
        {{ alert_message }}
      </CAlert>
    </div>
  </CRow>
</template>

<script>
export default {
  name: "MasterHubs",
  data() {
    return {
      all_sum_wallet: '',
      all_sum_counter: '',
      all_sum_weight: '',
      file_type: '',
      file_city: '',
      race_number: '',
      warning: false,
      success: false,
      alert_message: '',
      warningModal: false,
      isSelect: false,
      action_switcher: '',
      action_switcher_city: '',
      id: "",
      file_path: '',
      fileData: '',
      variables: '',
      tableItems: [],
      tableFields: [
        {key: "data", label: "Дата"},
        {key: "brk", label: "БРК "},
        {key: "ves", label: "Вес", _classes: "text-center"},
        {key: "kod", label: "Код"},
        {key: "fio", label: "ФИО"},
        {key: "naim", label: "Наим"},
        {key: "upk", label: "УПК"},
        {key: "file_city", label: "Адрес"},
        {key: "file_type", label: "Тип рейса"},
        {key: "race_number", label: "Номер рейса"},
        {key: "price_per_kg", label: "цена за кг"},
        {key: "price_per_place", label: "цена за место"},
        {key: "counter_place", label: "Колво мест"},
        {key: "summa", label: "Сумма"},
        {key: "action", label: "Действия"},
      ],
    };
  },
  mounted() {
    this.getParams();
  },
  methods: {
    changeItem(item, index) {
      let summa = 0;
      if (item.upk.length > 0) {
        summa = (parseFloat(item.ves) * parseFloat(item.price_per_kg)) + parseFloat(item.price_per_place);
      } else {
        summa = (parseFloat(item.ves) * parseFloat(item.price_per_kg)) + parseFloat(item.price_per_place) + item.upk;
      }
      let item_summa = summa;
      this.tableItems[index].summa = Math.round(parseFloat(item_summa));

      this.all_sum_wallet = 0;
      this.all_sum_counter = 0;
      this.all_sum_weight = 0;
      for (var i = 0; i < this.tableItems.length; i++) {
        this.all_sum_wallet += this.tableItems[i].summa
        this.all_sum_counter += this.tableItems[i].counter_place
        this.all_sum_weight += this.tableItems[i].ves
      }
    },
    getParams() {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/auth/variables/list').then(response => {
        app.variables = response.data.variables;
      }).catch(error => {

      });
    },
    deleteItem(item, index) {
      let app = this;
      app.tableItems.splice(index, 1);
    },
    onFileChange() {
      this.file_path = event.target.files[0];
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.fileData = e.target.result;
        }
        reader.readAsDataURL(input.files[0]);
      }
    },
    uploadToArray() {
      event.preventDefault();
      let app = this;
      axios.post('/auth/traffics/add-list', {data: app.tableItems})
          .then(({data}) => {
            app.success = true;
            app.interval = 0.5;
            app.alert_message = 'Данные успешно сохранены';
            setTimeout(function () {
              app.success = false;
            }, 3000);
            app.tableItems = [];
          }).catch(function (error) {

      });
    },
    convertToArray() {
      event.preventDefault();
      let app = this;
      app.loader = true;
      const config = {
        headers: {'content-type': 'multipart/form-data'}
      }
      let formData = new FormData();
      formData.append('file', app.file_path);
      formData.append('file_type', app.file_type);
      formData.append('file_city', app.file_city);
      formData.append('race_number', app.race_number);
      axios.post('/auth/convertor/excel-to-json', formData, config).then(function (response) {
        app.success = true;
        app.tableItems = response.data.array;
        app.all_sum_wallet = 0;
        app.all_sum_counter = 0;
        app.all_sum_weight = 0;
        for (var i = 0; i < app.tableItems.length; i++) {
          app.tableItems[i].action = true;
          if (app.tableItems[i].brk == 0) {
            app.tableItems.splice(i, 1);
          }
          app.all_sum_wallet += app.tableItems[i].summa
          app.all_sum_counter += app.tableItems[i].counter_place
          app.all_sum_weight += app.tableItems[i].ves
        }
        app.alert_message = 'Данные за парсены';
        setTimeout(function () {
          app.success = false;
        }, 3000)
      }).catch(function (error) {

      })
    },
    showErrors: function (errors) {
      let app = this;
      this.errors = errors;
      setTimeout(function () {
        app.errors = {};
      }, 3000)
    },
    showSuccess: function (message) {
      let app = this;
      app.success = message.data.message;
      setTimeout(function () {
        app.success = '';
      }, 3000)
    },

  },
};
</script>
<style>
.clapans .form-inline {
  margin-left: 15px;
}

.alert_block {
  position: fixed;
  right: 0;
  bottom: 0;
}
</style>
